.loginPageMainDiv{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;/*not always working*/
    min-height: 100%; /*not always working*/
    min-height: 100vh;

display: flex;
flex-direction: column;
justify-items: center;
justify-content: center;
align-items: center;
align-content: center;


background: url("./../resources/loginPageBack.jpeg") no-repeat center center fixed;
background-size: cover;
height: 100%;
overflow: hidden;

}
.loginPageMainDivImg{
    position: fixed; 
  top: 0; 
  left: 0; 
	
  /* Preserve aspet ratio */
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
}
.loginPageLoginFormMainDiv{
 width: 50% ;
 

 padding: 1rem;
 border-radius: 1rem;

 background:  rgba(0,0,0,0.5) ;
color: white;
  
 box-sizing: border-box;
 animation: loginPageLoginFormMainDivShadowAnimation linear 5s   infinite alternate, loginPageLoginFormMainDivScaleAnimation ease-in-out 3s infinite alternate;
 /*box-shadow: 0 0 20rem 1rem black;*/

 z-index: 0;
}


#inputUsername, #inputPassword{
    border-radius: 1rem;
}

.loginPageLoginFormButtonsDiv{
display: flex;
flex-direction: row;
justify-content:space-around;
margin-top: 5%;
/*align-items: baseline;z*/
}

button.loginFormButton{
    border-radius: 1rem;
}
button.loginFormButton:focus{
    box-shadow: none;
}


@keyframes loginPageLoginFormMainDivShadowAnimation {
    0% {
        box-shadow: 0 0 2rem 0.2rem red;
        
    }
    50% {
        box-shadow: 0 0 2rem 0.2rem green;
    }
    100% {
        box-shadow: 0 0 2rem 0.2rem blue;
    }
 
    
  }
  @media only screen and (orientation: portrait) {
    .loginPageLoginFormMainDiv{
    margin-bottom: 20vh;
    }

  @keyframes loginPageLoginFormMainDivScaleAnimation {
    0% {
       /* transform: scale(1);*/
      
      
           width:80%;
           padding: 5%  ;        
           border-radius: 0.5rem;
 
         
    }
    100% {
        
        width:90%;
        padding: 10%  ;        
        border-radius: 5rem;
 
    

}
   
  }
}
 

    @media only screen and (orientation: landscape)
    {


        @media only screen and (max-width: 1548px) {
        
     
            @keyframes loginPageLoginFormMainDivScaleAnimation {
                0% { 
                    width:30%;
                    padding:   1%  ;        
                    border-radius: 0.5rem;
            
                    }
                    
                100% {   
                width:35%;
                padding: 3.5%  ;        
                border-radius: 4rem;
         
                }
            }
        }
        @media only screen and (max-width: 1348px) {
        
     
            @keyframes loginPageLoginFormMainDivScaleAnimation {
                0% { 
                    width:35%;
                    padding:   1%  ;        
                    border-radius: 0.5rem;
            
                    }
                    
                100% {   
                width:40%;
                padding: 3.5%  ;        
                border-radius: 4rem;
         
                }
            }
        }
        @media only screen and (max-width: 1248px) {
        
     
            @keyframes loginPageLoginFormMainDivScaleAnimation {
                0% { 
                    width:38%;
                    padding:   1%  ;        
                    border-radius: 0.5rem;
            
                    }
                    
                100% {   
                width:43%;
                padding: 3.5%  ;        
                border-radius: 4rem;
         
                }
            }
        }
        @media only screen and (max-width: 1148px) {
        
     
            @keyframes loginPageLoginFormMainDivScaleAnimation {
                0% { 
                    width:40%;
                    padding:   1%  ;        
                    border-radius: 0.5rem;
            
                    }
                    
                100% {   
                width:45%;
                padding: 3.5%  ;        
                border-radius: 4rem;
         
                }
            }
        }
        @media only screen and (max-width: 1046px) {
        
     
            @keyframes loginPageLoginFormMainDivScaleAnimation {
                0% { 
                    width:50%;
                    padding:   1%  ;        
                    border-radius: 0.5rem;
            
                    }
                    
                100% {   
                width:55%;
                padding: 3.5%  ;        
                border-radius: 4rem;
         
                }
            }
        }
    @media only screen and (max-width: 1000px) {
        
     
        @keyframes loginPageLoginFormMainDivScaleAnimation {
            0% { 
                width:51%;
                padding:1%   ;        
                border-radius: 0.5rem;
        
                }
                
            100% {   
            width:56%;
            padding: 3.5%  ;        
            border-radius: 4rem;
     
            }
        }
    }
    @media only screen and (max-width: 880px) {
        
     
        @keyframes loginPageLoginFormMainDivScaleAnimation {
            0% { 
                width:58%;
                padding:1%   ;        
                border-radius: 0.5rem;
        
                }
                
            100% {   
            width:63%;
            padding: 3.5%  ;        
            border-radius: 4rem;
     
            }
        }
    }
    @media only screen  and (max-width: 740px){
        @keyframes loginPageLoginFormMainDivScaleAnimation {
            0% { 
                width:70%;
                padding: 1%  ;        
                border-radius: 0.5rem;
        
                }
            100% {   
            width:75%;
            padding: 3.5%  ;        
            border-radius: 4rem;
     
            }
        }
    }
  
@media only screen and (max-width: 600px){
     
    @keyframes loginPageLoginFormMainDivScaleAnimation {
        0% {
            /* transform: scale(1);*/
           
           
                width:80%;
                padding: 5%  ;        
                border-radius: 0.5rem;
           
              
         }
         100% {
             
             width:90%;
             padding: 10%  ;        
             border-radius: 5rem;
     
         
     
     }
    
}
}
}
