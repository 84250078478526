 .deviceAccessPageMaiDiv 
 {
     margin: 0;
     padding: 0;
     width: 100%;
     min-height: 200vh;
     background: url("./../../resources/loginPageBack.jpeg")   no-repeat center center fixed;
     background-clip: border-box;
     width: 100%;
     background-size: cover;

 }
 .deviceAccessPageDeviceDiv 
 {
    margin: 0;
    padding: 0;

    width:100%;
    padding: 1rem;
    font-size: 1.5rem;

    background: rgba(0,0,0,0.3);
    color:white;
    

    display: flex;
    flex-direction: row;
   flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: space-between;

 }
 .deviceAccessPageUsersDiv
 {
     width: 100%;
     display: flex;
     flex-direction: row;
    flex-wrap: wrap;
     justify-content: center;
     align-items: center;
     align-content: flex-start;
 }
 .deviceAccessPageUserDiv{
    margin: 1rem;
    padding: 1rem;
    border:none;
    border-radius: 1rem;
    box-shadow: 0 0 0.5rem 0.1rem rgba(0,0,0,0.5);
    background: rgba(255,255,255,0.1);
    color:white;
    -webkit-backdrop-filter: blur(10px) !important;
    backdrop-filter: blur(10px) !important;
    
 }
 .deviceAccessPageUserDiv:hover{
    
    box-shadow: 0 0 0.5rem 0.1rem rgba(255,0,0,0.8);
    cursor: pointer;
   
    
 }
.deviceAccessPageUserDiv:active{
    background: rgba(255,0,0,0.5);
}
 .deviceAccessPageTextInput 
 {
     font-size: 1rem;
     color:white;
     margin: 0 0.5rem 0 0;
     padding: 0.5rem;
     border-radius: 5rem;
     border:none;
     box-shadow: 0 0 0.5rem 0.05rem rgba(255,255,255,0.3);
     background:rgba(255,255,255,0.3);
 }
 .deviceAccessPageButton
 {
    margin:0;
    padding: 0.3rem ;

    color:white;
    text-shadow:  0 0 10px  #ff0000;
    

    border:none;
    border-radius: 0.5rem;
    background: rgba(0,0,0,0);
    box-shadow: 0 0 0.5rem 0.05rem rgba(255,255,255,0.5);
        word-wrap:break-word;
        white-space: normal;
        font-size: 1rem;
        
 }
 .deviceAccessPageButton:hover{
    box-shadow: 0 0 0.5rem 0.05rem rgba(255,255,255,0.3);
    background: rgba(255,255,255,0.2);
 
 }
 .deviceAccessPageButton:active{
  box-shadow: none;
  }