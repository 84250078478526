.topBarMainDiv
{
    width:100%;

    padding: 0;
    margin:0;
    border:none;

    top:0px;
    left: 0px;

    background: linear-gradient(black  , rgba(0,0,0,0.5)  );


    display:flex;
    flex-direction: row;
   /* justify-items:;*asta seteaza justify self */
    justify-content: space-between;
    align-items: space-between;
    align-content: space-between;

    
    box-shadow: 0 0 0.5rem 0.005rem white;

    animation: topBarMainDivColorAnimation  linear 5s  infinite alternate  ;
    position: -webkit-sticky; /* Safari */
  position: sticky;

}



 





.topBarSecondaryDiv{
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    justify-content: flex-start;
    align-items: center;/* asta e cheia pe mie ma intereseaja pe cross axis (verticala ) sa fie centrate sus jos ca pe orizontala oricum e ca e un singur elemen in div*/
    align-content: flex-start;
    padding: 0 1% 0 1%;
    
}


.flexGrowDiv{
    flex-grow: 1;
}

.topBarSecondaryDiv.topBarFlexGrowDiv{
    /*justify-items: flex-end;*/
    justify-content:flex-end;
    align-items: center; 
    /*align-content: flex-end;*/
}

button.topBarButton{
   
     
        border-radius: 1rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
   
}


button.topBarRightMargin{
   
     
   margin-right: 0.5rem;

}


div.topBarVerticalBar{
    height: 50%;
    min-height:  50%;
    max-height:  50%;
    border-left: thin solid white;
    margin-right: 0.3rem;
    box-shadow: 0.09rem 0    0.2rem 0.002rem white;
}


@keyframes topBarMainDivColorAnimation {
    0% {
      
        box-shadow: 0 0 1rem 0.005rem red;
        
    }
    50% {
       
        box-shadow: 0 0 1rem 0.005rem green;
    }
    100% {
      
        box-shadow: 0 0 1rem 0.005rem blue;
    }
}



@media only screen and (orientation: portrait) {
    .topBarMainDiv{
    min-height: 8vh;

    }
}
@media only screen and (orientation: landscape) {
    .topBarMainDiv{
    min-height: 8vh;

    }
}