.colorAttributeMainDiv
{
    min-width: 20%;
     max-width: 90%;
     
    /* /*min-height: 3rem; */
    padding:0.2rem;

    margin:0.2rem;


     
    display: flex;
    flex-direction: row;
    flex-wrap:  wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;

     
    background:white;/* linear-gradient(90deg,black 20%, rgba(0,0,0,0) 80%);*/
  border:none;
  box-shadow: 0 0 0.05rem 0.01rem white;  


    border-radius: 0.5rem;
}


.colorAttributeNameDiv::-webkit-scrollbar ,
 .colorAttributeValueDiv::-webkit-scrollbar , 
 .colorAttributeDisplayValueDiv::-webkit-scrollbar, 
 .colorAttributeBaseDiv::-webkit-scrollbar,
 .colorLimitDiv::-webkit-scrollbar{
    max-width: 0;
    max-height: 0;
}

.colorAttributeNameDiv::-webkit-scrollbar-track,  
.colorAttributeValueDiv::-webkit-scrollbar , 
.colorAttributeDisplayValueDiv::-webkit-scrollbar, 
.colorAttributeBaseDiv::-webkit-scrollbar-track,
.colorLimitDiv::-webkit-scrollbar-track{
    max-width: 0;
    max-height: 0;
    border-radius: 10px;
}

.colorAttributeNameDiv::-webkit-scrollbar-thumb ,
 .colorAttributeValueDiv::-webkit-scrollbar , 
 .colorAttributeDisplayValueDiv::-webkit-scrollbar, 
 .colorAttributeBaseDiv::-webkit-scrollbar-thumb,
 .colorLimitDiv::-webkit-scrollbar-thumb{
    border-radius: 10px;
    max-width: 0;
    max-height: 0;
}
.colorAttributeNameDiv
{
margin:0;
padding: 0;
overflow: scroll;
padding: 0.25rem 0 0.25rem 0;

}

.colorAttributeValueDiv{
    margin:0;
  
    overflow: scroll;
    --colorAttributeValueDiv-padding: 0 ;
    padding: var(--colorAttributeValueDiv-padding);
    padding: 0.2rem 0.2rem 0.2rem 0.2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;

}
.colorAttributeDisplayValueDiv{
    margin:0;
    padding: 0;
    overflow: scroll;
}


.colorAttributeBaseDiv
{
    min-width: 5rem;
    max-height: 1.5rem;
    min-height: 1.5rem;
    margin: 0;
    padding: 0;
    border-radius: 0.3rem;
    background-color: white;

    box-shadow: 0 0 0.2rem 0.01rem rgba(0,0,0,0.3);

/*
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    justify-content: flex-start;
*/
display: inline-block;
white-space: nowrap;
    word-wrap:none;
 

    text-align: center;

    position: relative;
    overflow: scroll;
}
.colorAttributeProgressDiv
{
    position: absolute;
    top:0px;
    left:0;
    --color-progress:0%;
    width:var(--color-progress);
    max-height: 1.5rem;
    min-height: 1.5rem;
    margin: 0;
    padding: 0;
    border-radius: 0.3rem;
    background: linear-gradient(90deg,rgba(0,0,255,calc(var(--color-progress)/1.5)),rgba(81, 250, 179, 0) 120% ), 
    linear-gradient(90deg,rgba(255,0,0,80%),rgba(255,0,0,0%) 120% ) ;

    
}

.colorLimitDiv{
    display: inline-block;
white-space: nowrap;
    word-wrap:none;
    text-align: center;

    
    overflow: scroll;

    padding: 0.2rem;
    min-width: 2rem;
}
.deviceColorAttributeGeneratedValueDiv{
    margin:0;
    padding:0;
    border:none;
    min-width: 3rem;
    min-height: 1rem;
    border-radius: 0.2rem;
    box-shadow: 0 0 0.15rem 0.08rem rgba(0,0,0,0.2);
}