.imgAttributeMainDiv
{
    min-width: 20%;
     max-width: 90%;
     
    /* /*min-height: 3rem; */
    padding:0.2rem;

    margin:0.2rem;


     
    display: flex;
    flex-direction: row;
    flex-wrap:  wrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;

     
    background:white;/* linear-gradient(90deg,black 20%, rgba(0,0,0,0) 80%);*/
    color: black;

    box-shadow: 0 0 0.05rem 0.01rem white; 


    border-radius: 0.5rem;
}


.imgAttributeNameDiv::-webkit-scrollbar , .imgAttributeValueDiv::-webkit-scrollbar{
    max-width: 0;
    max-height: 0;
}

.imgAttributeNameDiv::-webkit-scrollbar-track,  .imgAttributeValueDiv::-webkit-scrollbar{
    max-width: 0;
    max-height: 0;
    border-radius: 10px;
}

.imgAttributeNameDiv::-webkit-scrollbar-thumb , .imgAttributeValueDiv::-webkit-scrollbar{
    border-radius: 10px;
    max-width: 0;
    max-height: 0;
}
.imgAttributeNameDiv
{
margin:0;
padding: 0;
overflow: scroll;
padding: 0.25rem 0 0.25rem 0;
}

.imgAttributeValueDiv{
    margin:0;
    padding: 0;
    overflow: scroll;
    
/*
    max-height: inherit;
    height: inherit;
    */
    /* nu poti decat cu vh */
    
}