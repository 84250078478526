.dashboardPageMainDiv{
    width :100%;
    margin:0;
    padding:0;



    display: flex;
    flex-direction: column;

    justify-content: flex-start;
    




    min-height: 300vh;


    background: rgb(235,230,235);
}
.dashboardPageDevicesDiv{


    width :100%;
    margin:0;
    padding:1rem;
    display: flex;
    flex-direction: row;
    flex-flow:  wrap;
    justify-content: space-evenly;

 
    align-items: center;
    align-content: flex-start;

   /* min-height: 200vh;*/

    word-wrap: break-word;
}



/*
.dashboardPageDevicesDiv::after {
    content: "";
    flex: auto;
  }

  {
        display: grid;  
  grid-template-columns: repeat(auto-fill, 100px);  
  grid-gap: 1rem; 
  justify-content: space-between; }



  pui flex startt si pui exact latimea si margine asa dea perfect de la s la draepta cand a fukl randul si es per atuni si la s si al d
  */